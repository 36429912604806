// TODO: unused, remove?
// eslint-disable-next-line no-redeclare,no-unused-vars
function timer() {
  window.timerRunning = true;
  if (localStorage.getItem('timerMute')) $('#muteBtn').trigger(click); // mute
  const {title} = window;
  // FIXME btn was undefined
  let btn = {};

  // placeholder
  if (!btn.timer) {
    btn.timer = $('<li id="timer"></li>').appendTo('#headerRightButtons');
    contextMenu(btn.timer, {cancel: 'Schließen'}, function () {
      btn.end();
    });
  }

  // interval
  if (btn.timerInterval) clearInterval(btn.timerInterval);
  var time = localStorage.getItem('timer');
  if (!time) time = 3600;
  btn.timer.text(secondsToStr(time)).off(click);
  btn.timerInterval = setInterval(function () {
    time--;
    var timeStr = secondsToStr(time);
    btn.timer.text(timeStr);
    setTitle(title + ' ' + timeStr);

    // end
    if (time <= 0) {
      sendNotification({
        title: 'Timer',
        message: 'Timer Ende',
        ignoreMute: true,
      });
      clearInterval(btn.timerInterval);
      btn.timer.on(click, function () {
        btn.end();
      });
    }
  }, 1000);

  // end
  btn.end = function () {
    clearInterval(btn.timerInterval);
    btn.timer.text('');
    setTitle(title);
    delete window.timerRunning;
    if (localStorage.getItem('timerMute')) $('#muteBtn').trigger(click);
  };

  // chooser
  contextMenu(
    btn,
    {
      1: '1s',
      300: '5m',
      600: '10m',
      900: '15m',
      1800: '30m',
      3600: '1h',
      7200: '2h',
      self: 'Eigene Zeit',
    },
    function (type) {
      if (type == 'hide') return;
      var seconds = 0;
      if (type == 'self') seconds = prompt('Minuten eingeben') * 60;
      else seconds = type;
      if (!seconds) return;
      localStorage.setItem('timer', seconds);
      btn.trigger(click);
    }
  );
}
